import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Survey } from '@app/shared';
import { environment } from '@environment';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AngularTokenService } from 'angular-token';

const API_SURVEYS = environment.tokenAuth.apiBase;
const CACHE_KEY = "mlspaSurveys";
const CACHE_SIZE = 1;

@Injectable()
export class SurveysService {
  private cache$: Observable<Array<Survey>>;
  private reload$ = new Subject<void>();
  private _surveyId: number;

  constructor(
    private _http: HttpClient,
    private _tokenService: AngularTokenService
  ) {}

  // TODO: Allow for filtering? the surveys
  get surveys() {
    if(!this.cache$) {
      this.cache$ = this.requestSurveys();

      this.cache$.subscribe(next =>{
        localStorage[CACHE_KEY] = JSON.stringify(next)
      })
    }

    this.cache$ = this.cache$.pipe(
      startWith(JSON.parse(localStorage[CACHE_KEY] || "[]"))
    );

    return this.cache$;
  }

  getSurvey(surveySlug:string) {
    return this.requestSurvey(surveySlug);
  }

  private requestSurveys() {
    return this._http.get<Survey[]>(`${API_SURVEYS}/surveys`).pipe(
      map(response => response['items'])
    );
  }

  private requestSurvey(surveySlug: string) {
    return this._http.get(`${API_SURVEYS}/surveys`)
      .pipe(
        map(response => response)
      );
  }

  public forceReload() {
    this.reload$.next();
    this.cache$ = null;
  }
}
