import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { AngularTokenService } from 'angular-token';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "angular-token";
import * as i3 from "@angular/common/http";
var RoleGuard = /** @class */ (function () {
    function RoleGuard(_router, _tokenService, _http) {
        this._router = _router;
        this._tokenService = _tokenService;
        this._http = _http;
    }
    RoleGuard.prototype.canActivate = function (next, state) {
        var _this = this;
        return this.player.pipe(map(function (res) {
            Object.assign(_this._tokenService.currentUserData, { airtable: res });
            return true;
        }));
        // return true;
    };
    Object.defineProperty(RoleGuard.prototype, "player", {
        get: function () {
            return this._http.get(environment.api.internal + "users/me").pipe(map(function (usr) { return usr; }));
        },
        enumerable: true,
        configurable: true
    });
    RoleGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleGuard_Factory() { return new RoleGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AngularTokenService), i0.ɵɵinject(i3.HttpClient)); }, token: RoleGuard, providedIn: "root" });
    return RoleGuard;
}());
export { RoleGuard };
