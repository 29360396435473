import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { map } from 'rxjs/operators';

const API_BIOGRAPHY = environment.api.external;

@Injectable({
  providedIn: 'root'
})
export class BiographyService {
  constructor(private _http: HttpClient) { }

  getBiography(playerSlug:number) {
    return this.requestBiography(playerSlug);
  }

  private requestBiography(playerSlug: number) {
    return this._http.get(`${API_BIOGRAPHY}biography/${playerSlug}`)
      .pipe(
        map(response => response)
      );
  }
}
