import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ABOUT = 'about';
var ABOUT_OUR_MISSION = 'our-mission';
var ABOUT_OUR_TEAM = 'our-team';
var ABOUT_PLAYER_REPS = 'player-reps';
var ABOUT_EXECUTIVE_BOARD = 'executive-board';
var ABOUT_CHARITY = 'charity';
var API_CMS = environment.api.external;
var CACHE_KEY = 'mlspaCms';
var CmsService = /** @class */ (function () {
    function CmsService(_http) {
        this._http = _http;
        this.cache$ = {};
    }
    CmsService.prototype.getAbout = function () {
        return this.getContent(ABOUT);
    };
    CmsService.prototype.getOurMission = function () {
        return this.getContent(ABOUT_OUR_MISSION);
    };
    CmsService.prototype.getOurTeam = function () {
        return this.getContent(ABOUT_OUR_TEAM);
    };
    CmsService.prototype.getPlayerReps = function () {
        return this.getContent(ABOUT_PLAYER_REPS);
    };
    CmsService.prototype.getExecutiveBoard = function () {
        return this.getContent(ABOUT_EXECUTIVE_BOARD);
    };
    CmsService.prototype.getCharity = function () {
        return this.getContent(ABOUT_CHARITY);
    };
    CmsService.prototype.getContent = function (uri) {
        // TODO add cache
        return this._http.get("" + API_CMS + uri);
    };
    CmsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CmsService_Factory() { return new CmsService(i0.ɵɵinject(i1.HttpClient)); }, token: CmsService, providedIn: "root" });
    return CmsService;
}());
export { CmsService };
