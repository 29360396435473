import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CardArticle, CardArticleResponse } from '@app/shared';
import { environment } from '@environment';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

const API_ARTICLES = environment.api.external;
const CACHE_KEY = "mlspaArticles";
const CACHE_SIZE = 1;

@Injectable()
export class ArticlesService {
  private cache$: Observable<Array<CardArticle>>;
  private reload$ = new Subject<void>();
  private _articleId: number;

  constructor(private _http: HttpClient) { }

  // TODO: Allow for increating the articles
  get articles() {
    if(!this.cache$) {
      this.cache$ = this.requestArticles(1);

      this.cache$.subscribe(next =>{
        localStorage[CACHE_KEY] = JSON.stringify(next)
      })
    }

    this.cache$ = this.cache$.pipe(
      startWith(JSON.parse(localStorage[CACHE_KEY] || "[]"))
    );

    return this.cache$;
  }

  // TODO: Add in player Id
  get featured() {
    return this._http.get(`${environment.api.external}featured/0`).pipe(
      map(response => response)
    );
  }

  getArticle(articleSlug:string) {
    return this.requestArticle(articleSlug);
  }

  loadMore(page:number) {
    return this.requestArticles(page);
  }

  private requestArticles(page) {
    return this._http.get<CardArticleResponse>(`${API_ARTICLES}news`, {
      params: {
        page: page
      }
    }).pipe(
      map(response => response.data)
    );
  }

  private requestArticle(articleSlug: string) {
    return this._http.get(`${API_ARTICLES}article/${articleSlug}`)
      .pipe(
        map(response => response)
      );
  }

  public forceReload() {
    this.reload$.next();
    this.cache$ = null;
  }
}
