import { ErrorHandler, Injectable, InjectionToken, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@app/app-routing.module';
import { TitleService } from '@app/shared';
import { SharedModule } from '@app/shared/shared.module';
import { AngularTokenModule } from 'angular-token';
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

const rollbarConfig = {
  accessToken: 'cb7220ed0ea449ab9bf77849e4d22c98',
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: (environment.production) ? 'production' : 'development',
  enabled: environment.production
};

// TODO: Clean up
@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err:any) : void {
    var rollbar = this.injector.get(RollbarService);
    rollbar.error(err.orginalError || err);

    if(!environment.production) {
      console.log(err);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AngularTokenModule.forRoot(environment.tokenAuth),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en' },
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory },
    TitleService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(_titleService: TitleService) {
    _titleService.init();
  }
}
