import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_BIOGRAPHY = environment.api.external;
var BiographyService = /** @class */ (function () {
    function BiographyService(_http) {
        this._http = _http;
    }
    BiographyService.prototype.getBiography = function (playerSlug) {
        return this.requestBiography(playerSlug);
    };
    BiographyService.prototype.requestBiography = function (playerSlug) {
        return this._http.get(API_BIOGRAPHY + "biography/" + playerSlug)
            .pipe(map(function (response) { return response; }));
    };
    BiographyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BiographyService_Factory() { return new BiographyService(i0.ɵɵinject(i1.HttpClient)); }, token: BiographyService, providedIn: "root" });
    return BiographyService;
}());
export { BiographyService };
