import * as i0 from "@angular/core";
var LocalstorageService = /** @class */ (function () {
    function LocalstorageService() {
        this.APP_KEY = "MLSPA";
    }
    LocalstorageService.prototype.setData = function (data, key) {
        try {
            localStorage.setItem(this.APP_KEY + "." + key, JSON.stringify(data));
            return true;
        }
        catch (e) {
            console.warn(e);
            return false;
        }
    };
    LocalstorageService.prototype.getData = function (key) {
        var storage = localStorage.getItem(this.APP_KEY + "." + key);
        return (storage) ? JSON.parse(storage) : {};
    };
    LocalstorageService.prototype.clearData = function (key) {
        key.forEach(function (toRemove) {
            try {
                localStorage.removeItem(this.APP_KEY + "." + toRemove);
            }
            catch (e) {
                console.warn(e);
            }
        });
        return true;
    };
    LocalstorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalstorageService_Factory() { return new LocalstorageService(); }, token: LocalstorageService, providedIn: "root" });
    return LocalstorageService;
}());
export { LocalstorageService };
