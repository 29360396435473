import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AngularTokenService } from 'angular-token';
var API_SURVEYS = environment.tokenAuth.apiBase;
var CACHE_KEY = "mlspaSurveys";
var CACHE_SIZE = 1;
var SurveysService = /** @class */ (function () {
    function SurveysService(_http, _tokenService) {
        this._http = _http;
        this._tokenService = _tokenService;
        this.reload$ = new Subject();
    }
    Object.defineProperty(SurveysService.prototype, "surveys", {
        // TODO: Allow for filtering? the surveys
        get: function () {
            if (!this.cache$) {
                this.cache$ = this.requestSurveys();
                this.cache$.subscribe(function (next) {
                    localStorage[CACHE_KEY] = JSON.stringify(next);
                });
            }
            this.cache$ = this.cache$.pipe(startWith(JSON.parse(localStorage[CACHE_KEY] || "[]")));
            return this.cache$;
        },
        enumerable: true,
        configurable: true
    });
    SurveysService.prototype.getSurvey = function (surveySlug) {
        return this.requestSurvey(surveySlug);
    };
    SurveysService.prototype.requestSurveys = function () {
        return this._http.get(API_SURVEYS + "/surveys").pipe(map(function (response) { return response['items']; }));
    };
    SurveysService.prototype.requestSurvey = function (surveySlug) {
        return this._http.get(API_SURVEYS + "/surveys")
            .pipe(map(function (response) { return response; }));
    };
    SurveysService.prototype.forceReload = function () {
        this.reload$.next();
        this.cache$ = null;
    };
    return SurveysService;
}());
export { SurveysService };
