import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminResolver, PreflightGuard, RoleGuard, ZeroGuardService } from '@app/shared';
import { AngularTokenService } from 'angular-token';

const routes: Routes = [
  // Two
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    canActivate: [ AngularTokenService, PreflightGuard ],
    data: { title: "Administration" },
    resolve: { cres: AdminResolver }
  },
  // One
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [ AngularTokenService, PreflightGuard, RoleGuard ],
  },
  {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule',
    canActivate: [ AngularTokenService, PreflightGuard, RoleGuard ],
    data: {
      title: "Profile"
    }
  },
  // Zero
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule',
    canActivate: [ ZeroGuardService ]
  },
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  // Errors
  // TODO: Actual error pages
  // {
    // path: 'not-found',
    // loadChildren: './errors/not-found/not-found.module#NotFoundModule',
  // },
  {
    path: "**",
    redirectTo: '/auth/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: Boolean(history.pushState) === false,
  })],
  exports: [RouterModule],
  providers: [ ZeroGuardService, PreflightGuard, AdminResolver ]
})
export class AppRoutingModule { }
