import { NgModule } from '@angular/core';
import { MatIconModule, MatToolbarModule } from '@angular/material';
import { SharedModule } from '@app/shared/shared.module';
import { HeaderShortComponent } from './header-short.component';
@NgModule({
  imports: [
    MatIconModule,
    MatToolbarModule,
    SharedModule
  ],
  declarations: [HeaderShortComponent],
  exports: [HeaderShortComponent]
})
export class HeaderShortModule { }
