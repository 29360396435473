import { NgModule } from '@angular/core';

import { NavTileComponent } from './nav-tile.component';
import { SharedModule } from '@app/shared/shared.module';
import { MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    MatIconModule,
    SharedModule
  ],
  declarations: [NavTileComponent],
  exports: [NavTileComponent]
})
export class NavTileModule { }
