import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

const ABOUT = 'about';
const ABOUT_OUR_MISSION = 'our-mission';
const ABOUT_OUR_TEAM = 'our-team';
const ABOUT_PLAYER_REPS = 'player-reps';
const ABOUT_EXECUTIVE_BOARD = 'executive-board';
const ABOUT_CHARITY = 'charity';

const API_CMS = environment.api.external;
const CACHE_KEY = 'mlspaCms';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  private cache$:any= {};

  constructor(private _http: HttpClient) { }

  public getAbout() {
    return this.getContent(ABOUT);
  }

  public getOurMission() {
    return this.getContent(ABOUT_OUR_MISSION);
  }

  public getOurTeam() {
    return this.getContent(ABOUT_OUR_TEAM);
  }

  public getPlayerReps() {
    return this.getContent(ABOUT_PLAYER_REPS);
  }

  public getExecutiveBoard() {
    return this.getContent(ABOUT_EXECUTIVE_BOARD);
  }

  public getCharity() {
    return this.getContent(ABOUT_CHARITY);
  }

  private getContent(uri: String) {
    // TODO add cache
    return this._http.get(`${API_CMS}${uri}`);
  }
}
