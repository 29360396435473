import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { BoxedFullComponent } from './boxed-full.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [BoxedFullComponent],
  exports: [BoxedFullComponent]
})
export class BoxedFullModule { }
