import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from '@environment';
import { AngularTokenService } from 'angular-token';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _tokenService: AngularTokenService,
    private _http: HttpClient
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> | Promise<any> | boolean {
      return this.player.pipe(
        map(res => {
          Object.assign(this._tokenService.currentUserData, { airtable: res });
          return true;
        })
      );
      // return true;
  }

  get player(): Observable<any> {
    return this._http.get(`${environment.api.internal}users/me`).pipe(map(usr => usr));
  }
}
