import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment.dev';

@Injectable()
export class PreflightGuard implements CanActivate {
  constructor(
      public _router: Router,
      public _tokenService: AngularTokenService,
      public _http: HttpClient
    ) {}

  canActivate(): Observable<boolean> {
    return  this._tokenService.validateToken().pipe(
      map(res => {
        console.log('preflight', res);
        if(res.success) {
          this.userData.subscribe(res => {
            Object.assign(this._tokenService.currentUserData, { airtable: res });
            return true;
          });

          return true;
        } else {
          this.signOut();
          return false;
        }
      }),
      catchError(err => {
        this.signOut();
        return throwError(err);
      })
    );
  }

  signOut() {
    this._tokenService.signOut().subscribe(
      res => this._router.navigate(['auth/login']),
      errr => this._router.navigate(['auth/login'])
    );
  }

  get userData() {
    return this._http.get(`${environment.api.internal}users/me`).pipe(
      map(usr => usr)
    );
      // Object.assign(this._tokenService.currentUserData, { airtable: usr });
      // return true;
  }
}
