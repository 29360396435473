export const environment = {
  production: true,
  tokenAuth: {
    apiBase: "https://api.mlsplayers.org/",
    apiPath: null,
    signInPath:'auth/sign_in',
    signInRedirect: 'login',
    signInStoredUrlStorageKey: 'redirectTo',
    registerAccountCallback: 'https://staging.mlsplayers.org/auth/login',
    registerAccountPath: 'auth',
    resetPasswordCallback: 'https://staging.mlsplayers.org/auth/reset-password'
  },
  baseRegister: 'https://staging.mlsplayers.org/auth/login',
  api: {
    external: "https://mlsplayers.org/api/",
    internal: "https://api.mlsplayers.org/"
  }
}
