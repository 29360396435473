import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment.dev';
var PreflightGuard = /** @class */ (function () {
    function PreflightGuard(_router, _tokenService, _http) {
        this._router = _router;
        this._tokenService = _tokenService;
        this._http = _http;
    }
    PreflightGuard.prototype.canActivate = function () {
        var _this = this;
        return this._tokenService.validateToken().pipe(map(function (res) {
            console.log('preflight', res);
            if (res.success) {
                _this.userData.subscribe(function (res) {
                    Object.assign(_this._tokenService.currentUserData, { airtable: res });
                    return true;
                });
                return true;
            }
            else {
                _this.signOut();
                return false;
            }
        }), catchError(function (err) {
            _this.signOut();
            return throwError(err);
        }));
    };
    PreflightGuard.prototype.signOut = function () {
        var _this = this;
        this._tokenService.signOut().subscribe(function (res) { return _this._router.navigate(['auth/login']); }, function (errr) { return _this._router.navigate(['auth/login']); });
    };
    Object.defineProperty(PreflightGuard.prototype, "userData", {
        get: function () {
            return this._http.get(environment.api.internal + "users/me").pipe(map(function (usr) { return usr; }));
            // Object.assign(this._tokenService.currentUserData, { airtable: usr });
            // return true;
        },
        enumerable: true,
        configurable: true
    });
    return PreflightGuard;
}());
export { PreflightGuard };
