import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { AngularTokenService } from "angular-token";
import { Observable } from "rxjs";

@Injectable()
export class PlayerResolver implements Resolve<any> {
  //@ts-ignore
  userData = this._tokenService.currentUserData.airtable;

  constructor(
    private _router: Router,
    private _tokenService: AngularTokenService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Observable<any> | Promise<any> | boolean  {
    let roles = (this.userData !== undefined) ? this.userData.member.fields['Contact Type'] : false;

    if(!roles.includes('Player')) {
      // TODO: Return to page saying they dont have access/
      this._router.navigate(['dashboard/news']);
      return false;
    }

    return true;
  }
}
