import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardProfileComponent } from './card-profile.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [CardProfileComponent],
  exports: [CardProfileComponent]
})
export class CardProfileModule { }
