import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Message, RequestMessage } from '@app/shared';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

const API_CONTACT = environment.tokenAuth.apiBase;

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  constructor(private _http: HttpClient) { }

  sendMessage(message: Message) {
    return this.postMessage(message);
  }

  requestMessage(requestMessage: RequestMessage) {
    // TODO format request message
    const message = Object.keys(requestMessage).map(key => `${key}\n ${requestMessage[key]}`).join('\n\n');
    const payload = {
      message
    };
    return this.postMessage(payload);
  }

  private postMessage(message: Message) {
    return this._http.post(`${API_CONTACT}/request_contact`, message, httpOptions);
  }
}
