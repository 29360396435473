import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
var API_ARTICLES = environment.api.external;
var CACHE_KEY = "mlspaArticles";
var CACHE_SIZE = 1;
var ArticlesService = /** @class */ (function () {
    function ArticlesService(_http) {
        this._http = _http;
        this.reload$ = new Subject();
    }
    Object.defineProperty(ArticlesService.prototype, "articles", {
        // TODO: Allow for increating the articles
        get: function () {
            if (!this.cache$) {
                this.cache$ = this.requestArticles(1);
                this.cache$.subscribe(function (next) {
                    localStorage[CACHE_KEY] = JSON.stringify(next);
                });
            }
            this.cache$ = this.cache$.pipe(startWith(JSON.parse(localStorage[CACHE_KEY] || "[]")));
            return this.cache$;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ArticlesService.prototype, "featured", {
        // TODO: Add in player Id
        get: function () {
            return this._http.get(environment.api.external + "featured/0").pipe(map(function (response) { return response; }));
        },
        enumerable: true,
        configurable: true
    });
    ArticlesService.prototype.getArticle = function (articleSlug) {
        return this.requestArticle(articleSlug);
    };
    ArticlesService.prototype.loadMore = function (page) {
        return this.requestArticles(page);
    };
    ArticlesService.prototype.requestArticles = function (page) {
        return this._http.get(API_ARTICLES + "news", {
            params: {
                page: page
            }
        }).pipe(map(function (response) { return response.data; }));
    };
    ArticlesService.prototype.requestArticle = function (articleSlug) {
        return this._http.get(API_ARTICLES + "article/" + articleSlug)
            .pipe(map(function (response) { return response; }));
    };
    ArticlesService.prototype.forceReload = function () {
        this.reload$.next();
        this.cache$ = null;
    };
    return ArticlesService;
}());
export { ArticlesService };
