import { Routes } from '@angular/router';
import { AdminResolver, PreflightGuard, RoleGuard, ZeroGuardService } from '@app/shared';
import { AngularTokenService } from 'angular-token';
var ɵ0 = { title: "Administration" }, ɵ1 = {
    title: "Profile"
};
var routes = [
    // Two
    {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        canActivate: [AngularTokenService, PreflightGuard],
        data: ɵ0,
        resolve: { cres: AdminResolver }
    },
    // One
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
        canActivate: [AngularTokenService, PreflightGuard, RoleGuard],
    },
    {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule',
        canActivate: [AngularTokenService, PreflightGuard, RoleGuard],
        data: ɵ1
    },
    // Zero
    {
        path: 'auth',
        loadChildren: './auth/auth.module#AuthModule',
        canActivate: [ZeroGuardService]
    },
    {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
    },
    // Errors
    // TODO: Actual error pages
    // {
    // path: 'not-found',
    // loadChildren: './errors/not-found/not-found.module#NotFoundModule',
    // },
    {
        path: "**",
        redirectTo: '/auth/login'
    }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1 };
