import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {
  private APP_KEY:string = "MLSPA";

  constructor() { }

  public setData(data: any, key: string) {
    try {
      localStorage.setItem(`${this.APP_KEY}.${key}`, JSON.stringify(data));
      return true;
    } catch (e) {
      console.warn(e);
      return false;
    }
  }

  public getData(key: string) {
    let storage = localStorage.getItem(`${this.APP_KEY}.${key}`)

    return (storage) ? JSON.parse(storage) : {}
  }

  public clearData(key: Array<string>) {
    key.forEach(function(toRemove) {
      try {
        localStorage.removeItem(`${this.APP_KEY}.${toRemove}`);
      } catch (e) {
        console.warn(e);
      }
    });

    return true;
  }
}
