export const groupItemsByCategoryArray = (data) => {
  const structure = data.reduce((acc, item) => {
    item.category.map(cat => {
      if (acc.hasOwnProperty(cat)) {
        return acc[cat].push(item);
      }

      acc[cat] = [item];
    });

    return acc;
  }, {});

  return Object.keys(structure).map(key => ({
    category: key,
    group: structure[key],
  }));
}

export const groupItemsByCategoryObjArray = (data) => {
  const structure = data.reduce((acc, item) => {
    item.categories.map(cat => {
      if (acc.hasOwnProperty(cat.title)) {
        return acc[cat.title].items.push(item);
      }

      acc[cat.title] = {
        description: cat.description,
        items: [item]
      };
    });

    return acc;
  }, {});

  return Object.keys(structure).map(key => ({
    category: key,
    description: structure[key].description,
    group: structure[key].items,
  }));
}

export const groupItemsByCategoryString = (data) => {
  const structure = data.reduce((acc, item) => {
    if (acc.hasOwnProperty(item.category)) {
      acc[item.category].push(item);
    } else {
      acc[item.category] = [item];
    }

    return acc;
  }, {});

  return Object.keys(structure).map(key => ({
    category: key,
    group: structure[key],
  }));
}
