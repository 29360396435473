import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
var API_CONTACT = environment.tokenAuth.apiBase;
var ContactService = /** @class */ (function () {
    function ContactService(_http) {
        this._http = _http;
    }
    ContactService.prototype.sendMessage = function (message) {
        return this.postMessage(message);
    };
    ContactService.prototype.requestMessage = function (requestMessage) {
        // TODO format request message
        var message = Object.keys(requestMessage).map(function (key) { return key + "\n " + requestMessage[key]; }).join('\n\n');
        var payload = {
            message: message
        };
        return this.postMessage(payload);
    };
    ContactService.prototype.postMessage = function (message) {
        return this._http.post(API_CONTACT + "/request_contact", message, httpOptions);
    };
    ContactService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.ɵɵinject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };
