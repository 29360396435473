import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';

/* @name ZeroGuardService
 * @type AuthGuard
 * @description Protects level 0 routes from users who are currently signed in.
 * Level Zero routs consist of those that are open to the public eye.
 * Currently should only be: [login, forgot-password, create-account]
 */
@Injectable()
export class ZeroGuardService implements CanActivate {
    constructor(
      public _tokenService: AngularTokenService,
      public _router: Router
    ) {}

    canActivate(): boolean {
        if(this._tokenService.userSignedIn()) {
            this._router.navigate(['dashboard']);
            return false;
        }

        return true;
    }
}
