import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { AngularTokenService } from "angular-token";

@Injectable()
export class AdminResolver implements Resolve<any> {
  currentData: any;

  constructor(
    private _router: Router,
    private _tokenService: AngularTokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): any {
    this.currentData = this._tokenService.currentUserData;

    if (this.currentData.role !== "admin") {
      this._router.navigate(['dashboard/news']);
      return false;
    }
  }
}
