import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '@app/shared/shared.module';

import { CardArticleComponent } from './card-article.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule
  ],
  declarations: [CardArticleComponent],
  exports: [CardArticleComponent]
})
export class CardArticleModule { }
